[name="radio-container"] {
  width: max-content;
  opacity: 1;
  animation-name: fromLeft;
  animation-duration: .2s;
  animation-timing-function: ease-in;
  position: absolute;
  top: 0;
  right: 10%;
}

@keyframes fromLeft {
  from {
    opacity: 0;
    right: -10%;
  }

  to {
    opacity: 1;
    right: 10%;
  }
}

/*# sourceMappingURL=index.29c826ae.css.map */
